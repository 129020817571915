import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import tw, { css } from 'twin.macro'

export default function Leveringstider(props) {
  const data = useStaticQuery(graphql`
    query {
      craftLeveringGlobalSet {
        dateUpdated
        leveringText
        levering {
          serie
          materiale
          standardsalg
          projektsalg
          kviklevering
        }
      }
    }
  `)
  return (
    <div
      css={[
        props.content.layout === 'horizontal' &&
          tw`flex flex-wrap md:space-x-10 md:-mt-16 py-4 items-center border-t-2 border-white relative md:text-white`,
        props.content.layout === 'vertical' &&
          tw`flex flex-col flex-wrap pt-10 md:px-10 px-6 container`,
      ]}
      className="mm-mx-dynamic"
    >
      <h4
        css={[
          props.content.layout === 'vertical' && tw`mb-8`,
          css`
            @media (max-width: 768px) {
              flex-basis: 100%;
            }
          `,
        ]}
      >
        LEVERINGSTIDER
      </h4>
      {props.content.layout === 'horizontal' &&
        props.content.serie
          .map((s) =>
            data.craftLeveringGlobalSet.levering.find((g) => g.serie === s.slug)
          )
          .map((r, i) => {
            return (
              <React.Fragment key={i}>
                <p css={tw`mb-0 mt-0 mr-10 md:mr-0 font-bold uppercase`}>
                  Standard {r.standardsalg}
                </p>
                <p css={tw`mb-0 mt-0 font-bold uppercase`}>
                  Projekt {r.projektsalg}
                </p>
              </React.Fragment>
            )
          })}
      {props.content.layout === 'vertical' &&
        props.content.serie
          .map((s) =>
            data.craftLeveringGlobalSet.levering.find((g) => g.serie === s.slug)
          )
          .map((r, i) => {
            return (
              <div
                key={i}
                css={[
                  tw`md:flex md:space-x-10 mb-3 pb-2 border-b-2 border-sand`,
                ]}
              >
                <div
                  css={[
                    tw`mb-0 mt-0 mr-10 md:mr-0  uppercase`,
                    css`
                      width: 18rem;
                    `,
                  ]}
                >
                  <strong>
                    {r.serie === 'stm-tinium-2'
                      ? 'STM Tinium 2+'
                      : r.serie
                          .replaceAll('stm-tinium-', ' ')
                          .replaceAll('stm-', ' ')
                          .replaceAll('-', ' ')}{' '}
                  </strong>
                  {r.materiale}
                </div>
                <div css={tw`mb-0 mt-0 md:mr-10 md:mr-0 uppercase min-w-[160px]`}>
                  Standard {r.standardsalg}
                </div>
                <div css={tw`mb-0 mt-0  uppercase min-w-[180px]`}>Projekt {r.projektsalg}</div>
                <div css={tw`mb-0 mt-0  uppercase`}>{r.kviklevering}</div>
              </div>
            )
          })}
      {props.content.layout === 'vertical' && (
        <>
          <p>
            <i>
              Opdateret {data.craftLeveringGlobalSet.dateUpdated.split('T')[0]}
            </i>
          </p>
          <div
            css={tw`max-w-4xl`}
            dangerouslySetInnerHTML={{
              __html: data.craftLeveringGlobalSet.leveringText,
            }}
          ></div>
        </>
      )}
    </div>
  )
}
